import styled from 'styled-components'
import media from '../utils/media'

const LightBackground = styled.div`
  height: ${({ $mobileHeight, $height }) => $mobileHeight || $height + 'px'};
  width: 100%;
  background-color: var(--color-background-light);
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  z-index: -1;

  ${media.sm`
    height: ${({ $height }) => $height + 'px'};
  `}
`

export default LightBackground
